export default {
	name: 'TermsandConditions',
	created () {
		this.$nextTick(function () {
		})
	},
	mounted () {

	},
	props: [],
	data () {
		return {
			PDFsrc: '/CGS-CIMB General Terms & Conditions.pdf',
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {

	}
}
